.vtpControls {
  text-align: left;
}
.formRow {
  display: inline-flex;
  padding-left: 5px;
  padding-right: 5px;
}
.formGroup {
  flex: 0 1 1;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
