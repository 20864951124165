@import "../../style/colors.scss";

.navlink {
  color: white;
  padding-right: 15px;
}

.navLogo {
  height: 30px;
  margin-right: 6px;
}

a:hover {
  color: $exlattice-lightblue;
  text-decoration: none;
}
