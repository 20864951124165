@import "./colors.scss";

$theme-colors: (
  "primary": $exlattice-darkblue,
  "secondary": $exlattice-lightblue
);

button {
  margin-bottom: 10px;
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
