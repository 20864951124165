$brightred: #fb2525;
$mustard: #ffc107;
$vtk-viewer-background: rgb(72, 77, 99);
$exlattice-darkblue: #29336d;
$exlattice-lightblue: #3cc6f2;

$button: white;
$button-hover: $exlattice-lightblue;
$background: $exlattice-darkblue;
$text: white;
$even-table-row: lightgrey;
$table-row-hover: darkgrey;
$inactive-text: lightgrey;
$submit-green: #4caf50;
$caution-red: #f44336;

:export {
  buttonColor: $button;
  buttonHoverColor: $button-hover;
  backgroundColor: $background;
  textColor: $text;
  inactiveTextColor: $inactive-text;
  exLatticeDark: $exlattice-darkblue;
  exLatticeLight: $exlattice-lightblue;
}
