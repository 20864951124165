@import "../../style/colors.scss";

.loginContainer {
  width: 300px;
  background: $exlattice-darkblue;
  padding: 30px;
  margin: auto;
  margin-top: 70px;
}

.loginLogo {
  height: 180px;
  padding-bottom: 20px;
}

.loginFieldTitle {
  color: white;
}

.loginButton {
  color: $exlattice-darkblue;
  width: 100%;
  margin-top: 20px;
}

.errorMessage {
  color: $caution-red;
  margin-top: 5px;
}
