@import "../../style/colors";

.sideBar {
  padding-top: 20px;
}
.sideNav {
  margin-bottom: 20px;
  a:hover {
    background-color: $exlattice-lightblue !important;
    color: white !important;
  }
}
.submitButton {
  margin-bottom: 10px;
  width: 100%;
}
.notSelected {
  z-index: 0 !important;
  color: #495057 !important;
  background-color: #fff !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
}
.sideBar:hover .selected {
  background-color: $exlattice-darkblue !important;
}
.detailsContainer {
  padding: 0;
  max-height: 100%;
  height: 100%;
}
.formContainer {
  padding: 8px;
  flex: 1 1 0;
  overflow-y: scroll;
}
